"use client"

import { cn } from "@/lib/frontend/shadcn"
import { Text } from "@/components/ui/text"
import { Flex } from "@/components/ui/flex"
import { ButtonLink } from "@/components/ui/button"
import type { ApiStoreSearchResult, RequiredFunction } from "@/types"
import { useEffect, useRef, useCallback, useState } from "react"
import { NoOpErrorBoundary } from "@/components/ErrorBoundaries"
import { NoMatchingJobs } from "@/components/NoMatchingJobs"
import { StoresSidebarLoadingSkeleton } from "./StoresSidebarLoadingSkeleton"
import { useParams } from "@/components/ParamsProvider"
import { StoreCard } from "./StoreCard"
import { useStoreFeedLayer } from "@/lib/frontend/hooks/statsig"
import { JobPage } from "./JobPage"
import { useMap } from "./MapProvider"
import { useIsMedium } from "@/lib/frontend/util"
import { SlugHeroDetailsProps, SlugHeroDetails } from "./SlugHeroDetails"
import { StoreCardFeedback } from "./StoreCardFeedback"

import { useAnalytics } from "@/lib/frontend/hooks/useAnalytics"
import { useElementsRef } from "@/lib/frontend/hooks"
import { useIntersectionObserver } from "@react-hookz/web"

export interface StoresSidebarV2Props {
  stores: ApiStoreSearchResult[]
  selectedStoreId?: string
  selectedJobId?: string
  title?: React.ReactNode
  isLoadingStores?: boolean
  slugHeroDetails?: SlugHeroDetailsProps | null
  relatedSlugs?: Array<{ title: string; slug: string; icon: string | null }>
  onStoreClick?: (store: ApiStoreSearchResult) => void
  onJobClick?: ({ jobId, storeId }: Record<"jobId" | "storeId", string>) => void
  onJobClose?: () => void
  className?: string
  search?: string[]
  zoom?: number
}

export const StoresSidebar: React.FC<StoresSidebarV2Props> = ({
  stores,
  onStoreClick,
  selectedStoreId,
  selectedJobId,
  isLoadingStores,
  title,
  slugHeroDetails,
  relatedSlugs,
  onJobClick,
  onJobClose,
  className,
  search,
  zoom,
}) => {
  const { storeCardFeedbackPrompt } = useStoreFeedLayer()
  const analytics = useAnalytics()

  // #region SEO and slug hero
  // Manage expand/collapse state for SlugHero in JobSideBar so Virtualizer can measure component height
  // Also necessry to keep the SlugHero collapse while scrolling otherwise virtualizer will we-open it
  const [slugHeroExpanded, setSlugHeroDetailExpansion] = useState(true)
  const toggleSlugHeroDetail = () => {
    setSlugHeroDetailExpansion((curr) => !curr)
  }

  // Build a Title for SlugPage when not using HeroDetails
  const [searchParams] = useParams()
  const utmTermValue = searchParams.get("utm_term")
  const nearMeLower = "near me"

  if (utmTermValue) {
    title = utmTermValue.toLowerCase().includes(nearMeLower) ? utmTermValue : `${utmTermValue} ${nearMeLower}`
  }

  // #region Job Page
  // On job card click, we want to animate the job card sliding out. We must decouple this animation from the query
  // parameters changing so it can finish.
  //
  // We set this to true initially so that hot linking to jobs works.
  const [slideOut, setSlideOut] = useState(true)

  const handleJobClick = useCallback<NonNullable<StoresSidebarV2Props["onJobClick"]>>(
    ({ jobId, storeId }) => {
      onJobClick?.({ jobId, storeId })
      setTimeout(() => setSlideOut(true), 50)
    },
    [onJobClick]
  )

  const handleJobClose = useCallback(() => {
    setSlideOut(false)

    if (onJobClose) {
      setTimeout(() => onJobClose(), 300)
    }
  }, [onJobClose])

  // Disabling job page animation on mobile map view because animation don't play well with it
  const { mapVisible } = useMap()
  const isMedium = useIsMedium()
  const disableAnimationOnMobile = mapVisible && isMedium
  let selectedStoreIndex = selectedStoreId ? stores.findIndex((store) => store.id === selectedStoreId) : undefined
  if (selectedStoreIndex === -1) selectedStoreIndex = undefined
  const selectedJob = selectedJobId
    ? stores.flatMap((store) => store.jobs).find((job) => job.id === selectedJobId)
    : undefined
  const selectedStore = selectedStoreId ? stores.find((store) => store.id === selectedStoreId) : undefined
  const showJobPage = !!selectedJob && !!selectedStore

  const handleStoreClick: RequiredFunction<StoresSidebarV2Props["onStoreClick"]> = useCallback(
    (store) => {
      if (store.jobs.length) {
        handleJobClick({ jobId: store.jobs[0].id, storeId: store.id })
      }
      onStoreClick?.(store)
    },
    [onStoreClick, handleJobClick]
  )

  const [openStoreCardPrompt, setOpenStoreCardPrompt] = useState<string | null>(null)

  const onPromptOpen = (id: string) => {
    setOpenStoreCardPrompt(id)
    analytics.track("Store Card Prompt Opened", { store_id: id })
  }

  const onPromptClose = () => {
    setOpenStoreCardPrompt(null)
    analytics.track("Store Card Prompt Cancelled")
  }

  // #region Scrolling
  const storeSidebarContainer = useRef<HTMLDivElement>(null)

  // When the selected job scrolls out of view, we must close the job page
  const [storeCards, createStoreCardRef] = useElementsRef<HTMLDivElement>()
  const selectedStoreSidebarVisibilityObserver = useIntersectionObserver(
    !isMedium && selectedStoreId ? storeCards.current?.[selectedStoreId] : null,
    {
      root: storeSidebarContainer,
      threshold: [0.0, 1],
    }
  )

  const hideJobPageWhenScrolledOutOfView = useCallback(() => {
    if (
      selectedJobId &&
      selectedStoreSidebarVisibilityObserver &&
      !selectedStoreSidebarVisibilityObserver.isIntersecting
    ) {
      handleJobClose()
    }
  }, [selectedJobId, selectedStoreSidebarVisibilityObserver, handleJobClose])

  // If the user is on a slug page and scrolls a bit, show them an alert that
  // they can reset filters to see more jobs.
  const [shownSeeMoreJobsAlert, setShownSeeMoreJobsAlert] = useState(false)
  const handleScroll = useCallback(() => {
    setShownSeeMoreJobsAlert((hasShown) => {
      if (hasShown) return true
      if (
        !storeSidebarContainer.current ||
        window.location.pathname === "/" ||
        window.location.pathname.startsWith("/all-jobs")
      )
        return false

      const scrollableHeight = storeSidebarContainer.current.scrollHeight
      const triggerHeight = 0.1 * scrollableHeight
      return storeSidebarContainer.current.scrollTop > triggerHeight
    })
  }, [])

  useEffect(() => {
    const scrollToStoreOnPinClick = async (event: CustomEvent<{ storeId: string }>) => {
      storeCards.current?.[event.detail.storeId]?.scrollIntoView({ behavior: "smooth", block: "center" })
    }

    // @ts-expect-error Custom event listeners have bad types
    window.addEventListener("mapPinClicked", scrollToStoreOnPinClick)
    // @ts-expect-error Custom event listeners have bad types
    return () => window.removeEventListener("mapPinClicked", scrollToStoreOnPinClick)
  }, [storeCards])

  // When the user searches or zooms in, we need to scroll to the top of the list
  const scrollToTopHash = (search?.join(" ") ?? " ") + (zoom?.toString() ?? " ") + mapVisible.toString()

  useEffect(() => {
    if (storeSidebarContainer.current && scrollToTopHash) {
      storeSidebarContainer.current.scrollTop = 0
    }
  }, [scrollToTopHash])

  const heroComponent = slugHeroDetails && (
    <SlugHeroDetails
      {...slugHeroDetails}
      isExpanded={slugHeroExpanded}
      componentExpansionToggle={toggleSlugHeroDetail}
    />
  )

  if (isLoadingStores) {
    return (
      <StoresSidebarLoadingSkeleton
        hero={heroComponent}
        title={title && !slugHeroDetails}
        className={cn("row-start-2 md:col-start-2 row-end-2 md:col-end-2 col-span-full", className)}
      />
    )
  }

  return (
    <>
      {showJobPage && (
        <JobPage
          store={selectedStore}
          job={selectedJob}
          onClose={handleJobClose}
          className={cn(
            "row-start-2 col-start-1 row-end-2 md:row-start-2 overflow-y-auto z-[100] md:z-20 col-end-3 md:col-start-3 lg:w-[450px] w-auto",
            // Animation
            !disableAnimationOnMobile && [
              "transform transition-all ease-in-out duration-300 md:translate-y-0",
              slideOut ? "opacity-100" : "opacity-75",
              {
                // Mobile classes, slides up from the bottom
                "translate-y-0": slideOut,
                "translate-y-full": !slideOut,

                // Desktop classes, slides in from the left
                "md:translate-x-0": slideOut,
                "md:-translate-x-full": !slideOut,
              },
            ]
          )}
        />
      )}
      <Flex
        direction="col"
        className={cn(
          "bg-[#f8f9fa] h-full row-start-2 col-start-1 col-end- row-end-2 md:row-start-2 md:row-end-2 md:col-start-2 md:col-end-2 overflow-auto z-30",
          className
        )}
      >
        {title && !slugHeroDetails && (
          <Flex direction="row" justify="between" align="center" className={cn("px-2.5", title && "py-3")}>
            <Text as="h1" size="sm" weight="bold" transform="capitalize" lineClamp={1}>
              {title}
            </Text>
          </Flex>
        )}

        <div
          className={cn("overflow-auto")}
          ref={storeSidebarContainer}
          onScroll={() => {
            handleScroll()
            hideJobPageWhenScrolledOutOfView()
          }}
        >
          {heroComponent && <div className={cn("p-2.5")}>{heroComponent}</div>}
          {stores.map((store, i) => {
            return (
              <div
                className={cn("pb-2.5 px-2.5")}
                key={store.id}
                // @ts-expect-error React is really strict about null vs undefined and it truly does not matter here.
                ref={createStoreCardRef(store.id)}
              >
                <NoOpErrorBoundary>
                  {storeCardFeedbackPrompt() ? (
                    <StoreCardFeedback
                      data-testid="StoreCard"
                      key={store.id}
                      store={store}
                      onClick={handleStoreClick}
                      selected={store.id === selectedStoreId}
                      selectedJobId={selectedJobId}
                      position={i}
                      onJobClick={handleJobClick}
                      isFeedbackPromptOpen={openStoreCardPrompt === store.id}
                      onPromptOpen={() => onPromptOpen(store.id)}
                      onPromptClose={onPromptClose}
                    />
                  ) : (
                    <StoreCard
                      data-testid="StoreCard"
                      key={store.id}
                      store={store}
                      onClick={handleStoreClick}
                      selected={store.id === selectedStoreId}
                      selectedJobId={selectedJobId}
                      position={i}
                      onJobClick={handleJobClick}
                    />
                  )}
                </NoOpErrorBoundary>
              </div>
            )
          })}
          <div className={cn("pb-20 sm:pb-5 pt-3")}>
            <NoMatchingJobs
              text="Can't find what you're looking for? Check out some of our curated job pages near you."
              slugs={relatedSlugs}
            />
          </div>
        </div>
      </Flex>
      <ButtonLink
        replace
        className={cn(
          "fixed",
          "bottom-7",
          "right-7",
          "transition-opacity",
          !shownSeeMoreJobsAlert ? "opacity-0 invisible" : "opacity-100 visible"
        )}
        variant="darkPrimary"
        rounded="lg"
        href="/"
      >
        See more jobs &rarr;
      </ButtonLink>
    </>
  )
}
